/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import 'tailwindcss/dist/base.min.css'
import 'tailwindcss/dist/utilities.min.css'
import 'tailwindcss/dist/components.min.css'
import './src/css/spinner.css'

const onRouteUpdate = () => {
  const spMenu = document.querySelector('.sp-menu')
  const loader = document.querySelector('.loader')

  if (spMenu) {
    const s = document.createElement('script')

    s.src = 'https://menus.singleplatform.com/widget'
    s.setAttribute('data-display_menu', '1600145')
    s.setAttribute('data-base_font_size', '14px')
    s.setAttribute('data-item_background_color', '#f7f6f3')
    s.setAttribute('data-item_desc_font_color', '#000000')
    s.setAttribute('data-item_price_font_color', '#000000')
    s.setAttribute('data-item_title_font_color', '#000000')
    s.setAttribute('data-menu_desc_background_color', '#f7f6f3')
    s.setAttribute('data-menu_desc_font_color', '#000000')
    s.setAttribute('data-primary_background_color', '#f7f6f3')
    s.setAttribute('data-section_desc_background_color', '#f7f6f3')
    s.setAttribute('data-section_desc_font_color', '#000000')
    s.setAttribute('data-section_title_background_color', '#f7f6f3')
    s.setAttribute('data-section_title_font_color', '#340000')
    s.setAttribute('data-hide_cover_photo', 'true')
    s.setAttribute('data-hide_disclaimer', 'true')
    s.setAttribute('data-location', 'sherrys-cafe-cakes--catering')
    s.setAttribute('data-api_key', 'ke09z8icq4xu8uiiccighy1bw')
    s.setAttribute('id', 'singleplatform-menu')
    s.setAttribute('async', true)

    spMenu.appendChild(s)

    s.onload = () => {
      document.querySelector('iframe[name="menu"]').onload = loader.remove()
    }
  }
}

export { onRouteUpdate }
