module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sherrys-cafe","short_name":"sherrys","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/sherrys-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4a01093c0604fc81fad037a539c6f4d7"},
    },{
      plugin: require('../node_modules/gatsby-background-image-es5/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
